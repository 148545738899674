import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Unsubscribe = () => {
    const { partitionkey, rowkey } = useParams();
    const [responseData, setResponseData] = useState(null);

    const makeHttpRequest = async () => {
        // try {
        //   // Make your HTTP request here using axios or any other HTTP library
        //   const response = await axios.get(`https://your-api-endpoint/${partitionKey}/${rowKey}`);

        //   // Update state with the response data
        //   setResponseData(response.data);
        // } catch (error) {
        //   // Handle errors
        //   console.error('Error making HTTP request:', error);
        // }
    };

    useEffect(() => {
        const UnsubscribeUser = async () => {
            try {
                fetch(API_URL + '/UnsubscribeFilter/' + partitionkey + '/' + rowkey + '?code=' + UnsubscribeCode)
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        // Update state with the response data
                        setResponseData(data);

                    })
            } catch (error) {
                // Handle errors
                console.error('Error making HTTP request:', error);
            }
        };

        UnsubscribeUser(); // Call the fetchData function when the component mounts

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array ensures the effect runs only once on mount


    return (
        <main class="container ">
            <div class="container py-5">

                {responseData && (
                    <div>
                        <h3>You have succesfully unsubscribed for alerts {responseData}</h3>
                    </div>
                )}

            </div>
        </main>
    );
};

export default Unsubscribe;
