import React from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faFlagUsa } from '@fortawesome/free-solid-svg-icons'

import { ToastContainer, toast, Slide } from 'react-toastify';

// minified version is also included
import 'react-toastify/dist/ReactToastify.min.css';

// import 'bootstrap/dist/js/bootstrap.js';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js';

export default class Main extends React.Component {
    constructor() {
        super();
        this.state = {
            flagOptions: [
                {
                    'iso-code': 'GB',
                    'value': 'UK',
                    'siteid': 3
                }
                // ,
                // {
                //     'iso-code': 'US',
                //     'value': 'US',
                //     'siteid': 0
                // },
                // {
                //     'iso-code': 'AU',
                //     'value': 'Australia',
                //     'siteid': 15
                // },
                // {
                //     'iso-code': 'DE',
                //     'value': 'Germany',
                //     'siteid': 77
                // }

            ],
            selectedFlagOption: {
                'iso-code': 'GB',
                'value': 'UK',
                'siteid': 3
            },
            keywords: "",
            keywordOptions: [],
            searchingCategory: false,
            selectedCategoryName: "Any",
            categoryOptions: [{
                "categoryName": "Any",
                "categoryId": "0",
                "matchCount":""
            }],
            conditionOptions: [],
            email: "",
            sellerName: "",
            minPrice: '',
            maxPrice: '',
            format: "Any",
            condition: "Any",
            conditionId:"0",
            category: "0",
            isEmailValid: true
        };
        this.searchKeywords = this.searchKeywords.bind(this);
        this.updateCategoryOption = this.updateCategoryOption.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateKeywords = this.updateKeywords.bind(this);
        this.geteBayConditionOption = this.geteBayConditionOption.bind(this);


    }

    componentDidMount() {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl, { placement: 'right' })
        })

    }

    updateKeywords(value) {
        this.setState({ keywords: value });

        // Hack for AF warm-up
        if (this.state.keywords.length % 3 == 0)
            this.updateCategoryOption();
    }

    validateEmail(email) {
        // Basic email validation regex
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    }

    showToast(type, message) {

        toast(message, { type: type })

    };


    handleSubmit(e) {
        e.preventDefault();

        var data = {}
        // TODO: Make it dynamic to add more platforms
        data["Platform"] = "eBay"

        // Not globalid , sending iso code as ebay uses it in request 
        data["Region"] = this.state.selectedFlagOption["iso-code"]

        data["keyword"] = this.state.keywords
        data["Seller"] = this.state.sellerName

        if (data["Seller"] == '' && data["keyword"] == '')
            return this.showToast(toast.TYPE.ERROR, "Either enter a keyword or valid seller name ")

        data["Category"] = (this.state.category).toString()

        if (this.state.email == "")
            return this.setState({ isEmailValid: false }), this.showToast(toast.TYPE.ERROR, "Email is a required field")

        //We are getting validated email from the form
        data["Email"] = this.state.email

        data["MinPrice"] = this.state.minPrice == '' ? 0 : this.state.minPrice
        data["MaxPrice"] = this.state.maxPrice == '' ? 999999 : this.state.maxPrice
        data["Format"] = this.state.format
        data["Condition"] = this.state.condition
        data["ConditionID"] = this.state.conditionId

        console.log(data)

        fetch(API_URL + "/Filter?code=" + FilterCode, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },

            //make sure to serialize your JSON body
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status == 409)
                        return this.showToast(toast.TYPE.INFO, ' Similar alert already added')
                    else
                        return this.showToast(toast.TYPE.ERROR, ' Aww! something went wrong')
                }
                // Handle success here, e.g., show a success message
                this.showToast(toast.TYPE.SUCCESS, 'Your alert was added succesfully 🎉')
                this.setState({
                    keywords: "",
                    keywordOptions: [],
                    categoryOptions: [{
                        "categoryName": "Any",
                        "categoryId": "0",
                        "matchCount":""
                    }],
                    selectedCategoryName: "Any",
                    searchingCategory: false,
                    conditionOptions: [],
                    email: "",
                    sellerName: "",
                    minPrice: '',
                    maxPrice: '',
                    format: "Any",
                    condition: "Any",
                    conditionId:"0",
                    category: "0",
                    isEmailValid: true,
                })
            })
            .catch((error) => {
                this.showToast(toast.TYPE.ERROR, ' Aww! something went wrong');
                console.log(error);
            })
            .finally(() => {
                // setLoading(false);
            });


    }

    updateCategoryOption() {

        if (this.state.keywords != '') {
            this.setState({ searchingCategory: true })
            var { keywords } = this.state;
            fetch(API_URL + '/eBayCategorySearch?code=' + eBayCategorySearchCode + '&keywords=' + keywords.trim() + '&marketplace=' + this.state.selectedFlagOption['iso-code'])
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data['refinement']);

                    var categoryOptions = []
                    if (data['refinement']['categoryDistributions'] != null) {
                        categoryOptions = data['refinement']['categoryDistributions'];
                        console.log(categoryOptions)
                        categoryOptions.sort(function (a, b) {
                            return b.matchCount - a.matchCount
                        });
                        console.log(categoryOptions)

                        categoryOptions.unshift({
                            "categoryName": "Any",
                            "categoryId": "0",
                            "matchCount": ""
                        });
                    }
                    else
                        categoryOptions = [{
                            "categoryName": "Any",
                            "categoryId": "0",
                            "matchCount": ""
                        }];

                    this.setState({
                        categoryOptions: categoryOptions.slice(0, 7),
                        searchingCategory: false
                    },
                    );

                });
        }
    }

    geteBayConditionOption(categoryId) {

            var { category } = this.state;
            fetch(API_URL + '/eBayConditionSearch?code=' + eBayConditionSearchCode + '&categoryIds=' + categoryId.trim() + '&marketplace=' + this.state.selectedFlagOption['iso-code'])
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    var conditionOptions = []
                    if (data != null) {
                        conditionOptions = conditionOptions.concat(data);
                        this.setState({ conditionOptions });
                    }
                });
    }

    searchKeywords(keywordOption) {

        this.setState({
            keywords: keywordOption
        });

        fetch(API_URL + '/eBayKeywordSearch?code=iW2dFETCk0Y9B-bAndgPL8upyAosomUjGmSdzQI7txqRAzFuEWHjJw==&keyword=' + keywordOption.trim() + '&siteid=' + this.state.selectedFlagOption['siteid'])
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log(data['res']);

                var categoryOptions = []
                if (data['res']['categories'] != null) {
                    categoryOptions = data['res']['categories'];
                    categoryOptions.unshift({
                        "categoryName": "Any",
                        "categoryId": "0"
                    });
                }
                else
                    categoryOptions = [{
                        "categoryName": "Any",
                        "categoryId": "0"
                    }];

                this.setState({
                    keywordOptions: data['res']['sug'] || [],
                    categoryOptions: categoryOptions
                });

            });


    }



    render() {

        return (
            <>
                {/* <Navbar/> */}

                {/*   <div class="nav-scroller bg-body shadow-sm container">
                    <nav class="nav nav-underline" aria-label="Secondary navigation">
                         <a class="nav-link active" aria-current="page" href="#">Dashboard</a>
                        <a class="nav-link" href="#">
                            Friends
                            <span class="badge bg-light text-dark rounded-pill align-text-bottom">27</span>
                        </a>
                        <a class="nav-link" href="#">Suggestions</a> 
                    </nav>
                </div>*/}

                <main class="container ">
                    {/* <img loading="lazy" class="bg" src="../images/find.svg" alt=""/> */}
                    <div class="container py-5">
                        <div class="row align-items-center rounded-3 border shadow-lg svg-background">
                            <div class="col-lg-7 p-3 p-lg-5 pt-lg-3 ">
                                <h1 class="display-6 fw-bold lh-1">Introducing eSearchAlert</h1>
                                {/* <p class="lead">Your ultimate eBay search companion! Quickly set your filter. Get notified! Get excited!!</p> */}
                                <p class="lead">Curate eBay's Hidden Finds with Your Personal Finder</p>
                            </div>
                            <div class="col-md-10 mx-auto col-lg-5">
                                <form class="rounded-3 p-3 p-md-3 m-3 bg-light ">
                                    <div class="mb-3">
                                        <label for="Keywords" class="form-label fw-bold">Keywords</label>
                                        <div class="dropdown">
                                            <input type="text" class="form-control" id="keywords" placeholder="Enter keywords" value={this.state.keywords} onChange={(e => this.updateKeywords(e.target.value))} onBlur={this.updateCategoryOption} />
                                        </div>
                                    </div>

                                    <label for="Category" class="form-label fw-bold" >Category</label>
                                    <div class="row mb-3 g-3">
                                        {/* <div class="col-6 pe-5 border-end"> */}
                                        {/* <div class="col">
                                            <select class="form-select" aria-label="categoryOptions"  disabled={this.state.searchingCategory}>
                                                {
                                                    this.state.categoryOptions.map(categoryOption => (
                                                        <option onClick={() => this.setState({ category: categoryOption["categoryId"] })}>
                                                            {categoryOption["categoryName"]}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div> */}
                                        <div class="dropdown col">
                                            <input class="form-select spinner-grow-sm" placeholder='Any' type="text" value={this.state.selectedCategoryName} id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            </input>

                                            <ul class="dropdown-menu form-select" style={{ backgroundImage: "none", width: "96%" }} aria-labelledby="dropdownMenuButton">
                                                {
                                                    this.state.searchingCategory ?
                                                        <li>
                                                            <button class="dropdown-item disabled" type="button" disabled>
                                                                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                                Loading...
                                                            </button>
                                                        </li>
                                                        :
                                                        this.state.categoryOptions.map(categoryOption => (
                                                            <li class="dropdown-item" onClick={() => this.setState({ category: categoryOption["categoryId"], selectedCategoryName: categoryOption["categoryName"] }, this.geteBayConditionOption(categoryOption["categoryId"]))} >{categoryOption["matchCount"] != "" ? categoryOption["categoryName"] + " (" + categoryOption["matchCount"] + " items)" : categoryOption["categoryName"]}</li>
                                                        ))

                                                }


                                            </ul>
                                        </div>
                                        {/* <div class="col-6 ps-5 ">
                                            <button type="submit" class="btn btn-primary mb-3">Choose Manually</button>
                                        </div> */}
                                    </div>

                                    <div class="mb-3">
                                        <label for="Email" class="form-label fw-bold">Email</label>
                                        <input type="email" className={`form-control ${this.state.isEmailValid ? '' : 'is-invalid'}`}
                                            placeholder="Enter email" aria-label="Email" aria-describedby="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value.trim(), isEmailValid: this.validateEmail(e.target.value.trim()) })} />
                                        <div class="invalid-feedback">
                                            Please enter a valid email.
                                        </div>
                                    </div>
                                    <div class="accordion accordion-flush mb-3 MoreFilters" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    More Filters
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <div class="mb-3">
                                                        <label for="SellerName" class="form-label fw-bold">Seller Name</label>
                                                        <span aria-hidden="true" className='badge bg-light text-dark' data-bs-toggle="tooltip" data-bs-placement="left" title="Enter the seller's username showed on eBay">&#9432;</span>
                                                        <input type="text" class="form-control" placeholder="Enter Seller Name" aria-label="SellerName" aria-describedby="seller name" value={this.state.sellerName} onChange={(e) => this.setState({ sellerName: e.target.value.trim() })} />
                                                    </div>
                                                    <label for="Price" class="form-label fw-bold">Price</label>
                                                    <div class="row mb-3 g-3">
                                                        <div class="col">
                                                            <input type="number" class="form-control" placeholder="Min Price" aria-label="Price" aria-describedby="Min-Price" value={this.state.minPrice} onChange={(e) => this.setState({ minPrice: e.target.value.trim() })} />
                                                        </div>
                                                        <div class="col">
                                                            <input type="number" class="form-control" placeholder="Max Price" aria-label="Price" aria-describedby="Max-Price" value={this.state.maxPrice} onChange={(e) => this.setState({ maxPrice: e.target.value.trim() })} />
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div class="col-md-6">
                                                            <label for="format" class="form-label  fw-bold">Format</label>
                                                            <select id="format" class="form-select" value={this.state.format} onChange={(e) => this.setState({ format: e.target.value })}>
                                                                <option value="Any">Any</option>
                                                                <option value="BIN">Buy it Now</option>
                                                                <option value="AUC">Auction</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="condition" class="form-label  fw-bold">Condition</label>
                                                            <select id="condition" class="form-select" value={this.state.conditionId} onChange={(e) => this.setState({ condition: e.target[e.target.selectedIndex].text, conditionId:e.target[e.target.selectedIndex].value })}>
                                                                <option value="0">Any</option>
                                                                {this.state.conditionOptions != [] ? this.state.conditionOptions.map(item => <option value={item["conditionId"]}>{item["conditionDescription"]}</option>) : ""}
                                                            </select>
                                                        </div>
                                                        {/* <div class="col-md-4">
                                                            <label for="inputState" class="form-label  fw-bold">Frequency</label>
                                                            <select id="inputState" class="form-select">
                                                                <option selected>Choose...</option>
                                                                <option>...</option>
                                                            </select>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button class="w-100 btn btn-lg btn-primary" type="submit" onClick={e => this.handleSubmit(e)}>Sign up</button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="container px-4 py-4" id="featured-3">
                        <div class="row g-4 py-4 row-cols-1 row-cols-lg-3">
                            <div class="feature col">
                                <h2>♾️ Saved Searches</h2>
                                <p>Never miss out on the items you love with unlimited saved searches for eBay.</p>
                            </div>
                            <div class="feature col">
                                <h2>💎 Rare Finds</h2>
                                <p>Discover eBay's hidden treasures effortlessly. Your ultimate tool for uncovering the extraordinary.</p>
                            </div>
                            <div class="feature col">
                                <h2>🏛️ Seller Updates</h2>
                                <p>Monitor the latest additions from a seller and stay informed about their newest products.</p>
                            </div>
                        </div>
                    </div>
                </main>

                {/* feedback form */}
                {/* <Feedback/> */}

                <ToastContainer transition={Slide} />
            </>

        )
    }
}
