import React from 'react'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Main from './pages/Main'
import Navbar from './components/Navbar';
import Feedback from './components/Feedback/Feedback';
import Unsubscribe from './pages/Unsubscribe';
import Student from './components/Student';

const App = () => {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/unsubscribe/:partitionkey/:rowkey" element={<Unsubscribe/>} />
            </Routes>
            {/* <Feedback/> */}
        </BrowserRouter>
    );
}

export default App;