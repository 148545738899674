import React from 'react'
import {Link} from 'react-router-dom'

class Navbar extends React.Component {
    constructor(){
        super();
        this.state = {
            flagOptions: [
                {
                    'iso-code': 'GB',
                    'value': 'UK',
                    'siteid': 3
                }
                // ,
                // {
                //     'iso-code': 'US',
                //     'value': 'US',
                //     'siteid': 0
                // },
                // {
                //     'iso-code': 'AU',
                //     'value': 'Australia',
                //     'siteid': 15
                // },
                // {
                //     'iso-code': 'DE',
                //     'value': 'Germany',
                //     'siteid': 77
                // }

            ],
            selectedFlagOption: {
                'iso-code': 'GB',
                'value': 'UK',
                'siteid': 3
            }
        }
    }


    ToggleNavbar() {
        document.querySelector('.offcanvas-collapse').classList.toggle('open')
    }


    
    render() {
        return (
            <nav class="navbar navbar-expand-lg navbar-light bg-light container" aria-label="Main navigation">
                    <div class="container-fluid">
                        <a class="navbar-brand fs-3" href="/"><strong>eSearchAlert</strong></a>
                        <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" onClick={this.ToggleNavbar} aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                {/* <li class="nav-item">
                                    <a class="nav-link active" aria-current="page" href="#">FAQ</a>
                                </li> */}
                                <li class="nav-item">
                                    <div class="dropdown">
                                        <button class="btn btn-md dropdown-toggle" type="button" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span class={"flag-icon me-1 flag-icon-" + this.state.selectedFlagOption['iso-code'].toLowerCase()} />
                                            <span>{this.state.selectedFlagOption['value']}</span>
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu">
                                            {
                                                this.state.flagOptions.map(flagOption => (
                                                    <li>
                                                        <a class="dropdown-item" href="#" onClick={() => this.setState({ selectedFlagOption: flagOption })}>
                                                            <span class={"flag-icon me-1 flag-icon-" + flagOption['iso-code'].toLowerCase()} />
                                                            <span>{flagOption['value']}</span>
                                                        </a>
                                                    </li>
                                                ))
                                            }

                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            {/* <form class="d-flex">
                                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                <button class="btn btn-outline-success" type="submit">Search</button>
                            </form> */}
                        </div>
                    </div>
                </nav>
         )
    }
}

export default Navbar;